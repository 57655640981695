.preview-containter{
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    position: relative;
    border-top: 1px solid var(--shadow-gray);
    border-bottom: 1px solid var(--alert-red);
    padding: 2rem 0;
}
.container-destacado-especial::after{
    content: '';
    position: absolute;
    width: 50%;
    left:25%;
    height: 2px;
    bottom: 0;
    background-color: var(--alert-red);
}

.embebed-destacado-especial {
    position: relative;
    padding-bottom: 56.25%;
    height: 0px;
    overflow: hidden;
}
.embebed-destacado-especial iframe{

    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%; 
}

.center{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2rem;
}
.center h2{
    font-size: 2.25rem;
    color: var(--black);
    text-transform: uppercase;
}

.center p{
    font-size: 2.5rem;
    color: var(--alert-red);
}

.preview-containter-destacado-especial-nota{
    position: relative;
    padding-top: 2rem;
    border-bottom: 1px solid var(--shadow-gray);
    display: grid;
    grid-template-areas:  "titulo destacado-especial"
                          "bajada destacado-especial"
                          "bajada destacado-especial"
                          ;
  
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
}
.container-destacado-especial-nota{
    position: relative;
    border-bottom: 1px solid var(--shadow-gray);
    display: grid;
    grid-template-areas:  "titulo destacado-especial"
                          "bajada destacado-especial"
                          "bajada destacado-especial"
                          "bajada destacado-especial"
                          "bajada destacado-especial";
    grid-template-columns: 1fr 1fr;
}
.center-destacado-especial-nota{
    text-align: left;
    padding: 2rem;
	padding-top: 0px;
    grid-area: titulo;
    padding-bottom: 0px;	
}
.center-destacado-especial-nota h1{
    font-size: 1.8rem;
    color: var(--black);
    font-family: "Times New Roman", Times, serif;
    line-height: 2rem;
    clear: both;
}
.center-destacado-especial-nota a {float: left;}
.center-destacado-especial-nota h1 strong{
font-size: 1.8rem;
    color: var(--sky-blue);
    font-family: "Times New Roman", Times, serif;
}
.caja2 { grid-area: bajada; padding: 2rem; padding-top: 0px; }
.caja2 .author{
    color: var(--gray-text);
	font-size: 0.9rem;
	font-weight: 400;
    margin-top: 1.2rem;
    margin-left: 0.8rem;
    font-family: "Times New Roman", Times, serif;
}

.container-destacado-especial-nota .bajada-nota-destacado-especial {
    font-size: 1rem;
    color: var(--black);
	line-height: 1.4rem;
    font-weight: normal;
	border-left: 1px solid var(--black);
    padding-left: 10px;
}
.container-destacado-especial-nota .bajada-nota-destacado-especial p {
    font-weight: normal;
}
.embebed-destacado-especial-nota {
    position: relative;
    padding-bottom: 56.25%;
    height: 400px;
    overflow: hidden;
    grid-area: destacado-especial;
}
.embebed-destacado-especial-nota iframe{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%; 
}
.publi-destacado-especial-nota { margin-top: 10px;}
.texto-destacado-especial-nota {padding:6px 20px; border-bottom: 1px solid #CCC; padding-bottom: 8px;  width: fit-content; height: auto; color: #000; letter-spacing: 1px; font-weight: 600;}
.especial-destacado-especial-nota {padding: 9px; background-color: var(--orange-deep); border-radius: 18px; color: var (--white); width: 110px;}
.img-autor-destacado-especial-nota { width: 50px; height: 50px; border-radius: 50px; float: left; margin-top: 20px;}
.destacado-especial-sponsor {
    float: right;
}
.destacado-especial-sponsor-auspicia {
    float: left;
    font-weight: 100;
    margin-top: .4rem;
    color: #333;
    font-size: .8rem;
}
.compartir-destacado-especial img {width: 30px; height: auto; margin-top: 1rem; margin-right: .2rem;}
.compartir-destacado-especial p { margin: 9px 5px 0px 0px; float: left; color: var(--gray-text); font-size: 0.8rem; font-weight: 400; margin-left: 0.8rem;}
.compartir-destacado-especial {float: right;}
.compartir-destacado-especial svg {width: 35px; height: auto;}

@media (max-width: 800px) {
    .caja2  {order:3; padding: 0px;}

    .embebed-destacado-especial-nota {
    order: 1;
    height: 220px;
    }

    .container-destacado-especial{
        grid-template-columns: 1fr;
        padding-top: 0;
        padding-bottom: .5rem;
    }
    
    .container-destacado-especial::after{
        display: none;
    }

    .center {
        padding: 1rem;
    }

    .texto-destacado-especial-nota {
        font-size: .9rem;
        padding: 10px 0px;
        padding-bottom: 5px;
    }

.container-destacado-especial-nota {
    display: flex;
    flex-direction: column;
    padding-top: 0px;
}
.center-destacado-especial-nota{
	padding: 0rem;
	margin-bottom: 0px;
    background: none;
    order: 1;  

}
.center-destacado-especial-nota h1{
	 margin-bottom: 1rem;
     font-size: 1.6rem;
     line-height: 1.9rem;
}	
.center-destacado-especial-nota h1 strong{
    font-size: 1.6rem;
    line-height: 1.9rem;
}	
.center-destacado-especial-nota p{
	border: none;
	padding: 0;
    order: 3;
}	
.center-destacado-especial-nota {
	border: none;
}	
.center-destacado-especial-nota .author, .caja2{
    margin-left: 0rem;
    margin-bottom: 10px;

}
.container-destacado-especial-nota .bajada-nota-destacado-especial{ margin-top: 1rem;}
.destacado-especial-sponsor-auspicia {
    display: none;
}
.compartir-destacado-especial img { margin-top: .7rem;}
}
